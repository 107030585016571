@import "@styles/variables.scss";
@import "@styles/responsive-helpers.scss";

.login-page {
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  z-index: 350;
  display: flex;
  height: 100%;
  width: 100vw;
  background: $background;

  .login-side {
    position: relative;
    color: $text;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    height: 100vh;

    .site-logo {
      top: 6rem;
      left: 0;
      margin-right: auto;
      margin-left: calc((100% - 325px) / 2);
      svg {
        width: 122px;
        height: 27px;
      }

      @include respond-above(md) {
        position: absolute;
      }
    }

    .login-info {
      max-width: 325px;
      margin: auto;

      .login-title {
        color: $primary;
        line-height: 28px;
        font-weight: 500;
        font-size: 2rem;
        margin-bottom: 3.2rem;
      }
      .login-description {
        color: $text-light;
        margin-bottom: 3.2rem;
      }
      .login-button {
        margin-top: 3.2rem;
        a {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .login-content {
    position: relative;
    width: 60%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 60px;

    > svg {
      background: #f1f2f3;
      position: absolute !important;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    // Hide content in tablet/mobile view
    @include respond-below(md) {
      display: none !important;
    }

    .top-row {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      max-width: $max-width;
      padding-right: 100px;
      a {
        color: #448fac;
        font-weight: 500;
        font-size: 20px;
        float: right;
      }
      a:first-child {
        margin-right: 40px;
      }
    }

    .login-carousel {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: $max-width;
      margin: auto;

      .carousel-container {
        width: 100%;
        max-width: $max-width;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .login-side {
    @include respond-below(md) {
      background: $primary-gradient;
      width: 100%;
      padding: 20px;
      .site-logo {
        margin: 0 auto;
        margin-top: auto;
        svg path:first-of-type {
          fill: #fff !important;
        }
      }
      .login-info {
        margin-top: 3.2rem;
        .login-title {
          color: #fff;
          text-align: center;
          margin-bottom: 6.4rem;
        }
        .login-description {
          text-align: center;
          color: #fff;
        }
        .login-button {
          color: $primary !important;
          background: #fff !important;
          margin: auto;
          svg path {
            stroke: $primary;
          }
        }
      }
    }
  }
}
