$primary: #35b9bf;
$primary-gradient: linear-gradient(71.21deg, #3d9caa 0%, #779af7 100%);

// TODO: Set correct color vars
$primary-dark: #608808;
$primary-light: #a1e213;
$primary-touch-darker: #82b70c;

$secondary: #f19494;
$secondary-dark: red;
$secondary-light: #ff8989;

$tertiary: #448fac;
$tertiary-dark: #3b86a3;
$tertiary-light: #448fac;

$text: #585858;
$text-dark: #32373a;
$text-light: #888888;

$warning: #f19494;
$warning-light: #ff8989;

$success: #45be3c;

$background: #f0f5fb;
$background-light: #f9f9f9;
$border: #dbdbdb;
$border-light: #e1e1e6;

$max-width: 1200px;
$max-modal-height: 90vh;

$content-width: 81rem;
$sider-width: 27rem;
$sider-width-collapsed: 10rem;

$header-height: 8.8rem;

$mobile-nav-height: 32rem;
$mobile-nav-height-collapsed: 12rem;

$font: "Circular Std";
